import { MemberRole } from '@index/interfaces/member-role';
import { createAction, props } from '@ngrx/store';
import { GthEventItemModel, GthTeamModel, GthTeamSessionModel } from '@sentinels/models';

export enum TeamsActionTypes {
  SetActiveTeam = '[Active Team] Set Active Team',

  SetActiveTeamById = '[Active Team] Set Active Team by Id',
  SetActiveTeamByIdSuccess = '[Active Team] Set Active Team by Id Success',
  SetActiveTeamByIdError = '[Active Team] Set Active Team by Id Error',

  LoadEvents = '[Active Team] Load Events',
  LoadEventsSuccess = '[Active Team] Load Events Success',
  LoadEventsError = '[Active Team] Load Events Error',

  LoadTeamMemberRoles = '[Active Team] Load Team Roles',
  LoadTeamMemberRolesSuccess = '[Active Team] Load Team Roles Success',
  LoadTeamMemberRolesError = '[Active Team] Lead Team Roles Error',

  LoadSessions = '[Active Team] Load Sessions',
  LoadSessionsSuccess = '[Active Team] Load Sessions Success',
  LoadSessionsError = '[Active Team] Load Sessions Error',

  SetCurrentSession = '[Active Team] Set Current Session',

  LoadSeasons = '[Active Team] Load Seasons',
  LoadSeasonsSuccess = '[Active Team] Load Seasons Success',
  LoadSeasonsError = '[Active Team] Load Seasons Error',

  LoadSeasonGroups = '[Active Team] Load Season Groups',
  LoadSeasonGroupsSuccess = '[Active Team] Load Season Groups Success',
  LoadSeasonGroupsError = '[Active Team] Load Season Groups Error',
}

export const SetActiveTeam = createAction(
  TeamsActionTypes.SetActiveTeam,
  props<{ activeTeam: GthTeamModel }>(),
);

export const SetActiveTeamById = createAction(
  TeamsActionTypes.SetActiveTeamById,
  props<{ activeTeamId: string }>(),
);

export const SetActiveTeamByIdSuccess = createAction(
  TeamsActionTypes.SetActiveTeamByIdSuccess,
  props<{ activeTeam: GthTeamModel }>(),
);

export const SetActiveTeamByIdError = createAction(
  TeamsActionTypes.SetActiveTeamByIdError,
  props<{ error: unknown }>(),
);

export const LoadEvents = createAction(
  TeamsActionTypes.LoadEvents,
  props<{ activeTeamId: string; activeSessionId?: string }>(),
);

export const LoadEventsSuccess = createAction(
  TeamsActionTypes.LoadEventsSuccess,
  props<{ events: GthEventItemModel[] }>(),
);

export const LoadEventsError = createAction(
  TeamsActionTypes.LoadEventsError,
  props<{ error: unknown }>(),
);

export const LoadTeamMemberRoles = createAction(
  TeamsActionTypes.LoadTeamMemberRoles,
  props<{ activeTeamId?: string }>(),
);

export const LoadTeamMemberRolesSuccess = createAction(
  TeamsActionTypes.LoadTeamMemberRolesSuccess,
  props<{ teamMemberRoles: MemberRole[] }>(),
);

export const LoadTeamMemberRolesError = createAction(
  TeamsActionTypes.LoadEventsError,
  props<{ error: unknown }>(),
);

export const LoadSessions = createAction(
  TeamsActionTypes.LoadSessions,
  props<{ activeTeamId?: string }>(),
);

export const LoadSessionsSuccess = createAction(
  TeamsActionTypes.LoadSessionsSuccess,
  props<{ sessions: any[] }>(),
);

export const LoadSessionsError = createAction(
  TeamsActionTypes.LoadSessionsError,
  props<{ error: unknown }>(),
);

export const SetCurrentSession = createAction(
  TeamsActionTypes.SetCurrentSession,
  props<{ session: GthTeamSessionModel | null }>(),
);

export const LoadSeasons = createAction(
  TeamsActionTypes.LoadSeasons,
  props<{ activeTeamId?: string }>(),
);

export const LoadSeasonsSuccess = createAction(
  TeamsActionTypes.LoadSeasonsSuccess,
  props<{ seasons: any[] }>(),
);

export const LoadSeasonsError = createAction(
  TeamsActionTypes.LoadSeasonsError,
  props<{ error: unknown }>(),
);

export const LoadSeasonGroups = createAction(
  TeamsActionTypes.LoadSeasonGroups,
  props<{ activeTeamId?: string }>(),
);

export const LoadSeasonGroupsSuccess = createAction(
  TeamsActionTypes.LoadSeasonGroupsSuccess,
  props<{ seasonGroups: any[] }>(),
);

export const LoadSeasonGroupsError = createAction(
  TeamsActionTypes.LoadSeasonGroupsError,
  props<{ error: unknown }>(),
);
